













































































import { Component, Vue, PropSync, Watch } from "vue-property-decorator";
import nueva_platoModule from "@/store/modules/visualizacion_dieta/nueva_plato-module";
import alimentoModule from "@/store/modules/visualizacion_dieta/alimento-module";
import suplementoModule from "@/store/modules/visualizacion_dieta/suplemento-module";
import { FilterDataDto } from "@/shared/dtos/visualizacion_dieta/FilterDataDto";
import { ClassColumnDataTable } from "@/components/DatatableGenerico/ClassColumnDataTable";
import { datatypes } from "@/components/DatatableGenerico/datatypes";
import { dieta_visualizacion } from "@/shared/dtos/visualizacion_dieta/dieta_visualizacion";
import dieta_visualizacionModule from "@/store/modules/visualizacion_dieta/dieta_visualizacion-module";
@Component({
  components: {
    DataTableFilterPlatos: () =>
      import("@/components/DataTableFilterDieta/DataTableFilterPlatos.vue"),
    DataTableFilterAlimentos: () =>
      import("@/components/DataTableFilterDieta/DataTableFilterAlimentos.vue"),
    DataTableFilterSuplementos: () =>
      import("@/components/DataTableFilterDieta/DataTableFilterSuplementos.vue")
  }
})
export default class DialogAddPlatoDieta extends Vue {
  @PropSync("shwadd") shwdialog!: boolean;
  @PropSync("add_in") in_dia!: dieta_visualizacion;
  @PropSync("id_dieta") id_dietasync!: number;
  @PropSync("id_paciente") id_pacientesync!: number;

  public filter_search: FilterDataDto = new FilterDataDto();

  public radios: string = "1";

  public ColumnasPlatos: ClassColumnDataTable[] = [];
  public ColumnasAlimentos: ClassColumnDataTable[] = [];

  public created() {
    this.search();
  }

  public get propiedades_dialog() {
    if (this.$vuetify.breakpoint.xsOnly) {
      return {
        fullscreen: true
      };
    }
    return {
      width: this.$vuetify.breakpoint.width - 100
    };
  }

  public get NombreSearch() {
    switch (this.radios) {
      case "1":
        return " plato";
        break;
      case "2":
        return " suplemento";
        break;
      case "3":
        return " alimento";
        break;
      default:
        break;
    }
    return "";
  }
  public get isPlato() {
    switch (this.radios) {
      case "1":
        return true;
        break;
    }
    return false;
  }

  public get classe() {
    switch (this.radios) {
      case "1":
        return "title_plato";
        break;
      case "2":
        return "title_suplemento";
        break;
      case "3":
        return "title_alimento";
        break;
      default:
        break;
    }
    return "";
  }
  public get color_btn() {
    switch (this.radios) {
      case "1":
        return "#009587";
        break;
      case "2":
        return "#ebbe58";
        break;
      case "3":
        return "#7bc5fe";
        break;
      default:
        break;
    }
    return "";
  }
  public get bordes() {
    this.ColumnasPlatos = [];
    this.ColumnasAlimentos = [];
    switch (this.radios) {
      case "1":
        this.ColumnasPlatos.push(
          new ClassColumnDataTable(
            "nombre",
            "Nombre",
            datatypes.string,
            true,
            datatypes.maxlength
          )
        );
        this.ColumnasPlatos.push(
          new ClassColumnDataTable(
            "porcentaje_gr",
            "% gr.",
            datatypes.number,
            true,
            datatypes.maxlength
          )
        );
        this.ColumnasPlatos.push(
          new ClassColumnDataTable(
            "porcentaje_glucidos",
            "% Hc",
            datatypes.number,
            true,
            datatypes.maxlength
          )
        );
        this.ColumnasPlatos.push(
          new ClassColumnDataTable(
            "porcentaje_protein",
            "% Prt.",
            datatypes.number,
            true,
            datatypes.maxlength
          )
        );
        this.ColumnasPlatos.push(
          new ClassColumnDataTable(
            "apropiado_desayuno",
            "Desayuno",
            datatypes.boolean,
            true,
            datatypes.maxlength
          )
        );
        this.ColumnasPlatos.push(
          new ClassColumnDataTable(
            "apropiado_tentempie",
            "Tentempié",
            datatypes.boolean,
            true,
            datatypes.maxlength
          )
        );
        this.ColumnasPlatos.push(
          new ClassColumnDataTable(
            "apropiado_comida",
            "Comida",
            datatypes.boolean,
            true,
            datatypes.maxlength
          )
        );
        this.ColumnasPlatos.push(
          new ClassColumnDataTable(
            "apropiado_merienda",
            "Merienda",
            datatypes.boolean,
            true,
            datatypes.maxlength
          )
        );
        this.ColumnasPlatos.push(
          new ClassColumnDataTable(
            "apropiado_cena",
            "Cena",
            datatypes.boolean,
            true,
            datatypes.maxlength
          )
        );
        this.ColumnasPlatos.push(
          new ClassColumnDataTable(
            "apropiado_recena",
            "Recena",
            datatypes.boolean,
            true,
            datatypes.maxlength
          )
        );
        this.ColumnasPlatos.push(
          new ClassColumnDataTable(
            "apropiado_postre",
            "Postre",
            datatypes.boolean,
            true,
            datatypes.maxlength
          )
        );
        break;
      case "2":
      case "3":
        this.ColumnasAlimentos.push(
          new ClassColumnDataTable(
            "nombre",
            "Nombre",
            datatypes.string,
            true,
            datatypes.maxlength
          )
        );
        this.ColumnasAlimentos.push(
          new ClassColumnDataTable(
            "porcentaje_gr",
            "% gr.",
            datatypes.number,
            true,
            datatypes.maxlength
          )
        );
        this.ColumnasAlimentos.push(
          new ClassColumnDataTable(
            "porcentaje_glucidos",
            "% Hc",
            datatypes.number,
            true,
            datatypes.maxlength
          )
        );
        this.ColumnasAlimentos.push(
          new ClassColumnDataTable(
            "porcentaje_protein",
            "% Prt.",
            datatypes.number,
            true,
            datatypes.maxlength
          )
        );
        break;
      default:
        break;
    }
    switch (this.radios) {
      case "1":
        return "bordes_plato";
        break;
      case "2":
        return "bordes_suplemento";
        break;
      case "3":
        return "bordes_alimento";
        break;
      default:
        break;
    }
    return "";
  }

  public get datosPlatos() {
    return nueva_platoModule.all_nueva_platos;
  }

  public get datosAlimentos() {
    return alimentoModule.all_alimentos;
  }

  public get datosSuplementos() {
    return suplementoModule.all_suplementos;
  }

  @Watch("radios")
  public async search() {
    switch (this.radios) {
      case "1":
        nueva_platoModule.getall_platos({
          id_dieta: this.id_dietasync,
          id_paciente: this.id_pacientesync
        });
        break;
      case "2":
        suplementoModule.getall_suplementos({
          id_dieta: this.id_dietasync,
          id_paciente: this.id_pacientesync
        });
        break;
      case "3":
        alimentoModule.getall_alimentos({
          id_dieta: this.id_dietasync,
          id_paciente: this.id_pacientesync
        });
        break;
      default:
        break;
    }
  }
  public add_plato(id_estructura: number, tipo: number) {
    this.in_dia.id_estructura = id_estructura;
    this.in_dia.id_tipo_dato = tipo;
    switch (tipo) {
      case 1:
        this.in_dia.nombre = nueva_platoModule.all_nueva_platos.filter(
          (x: FilterDataDto) => x.id_estructura === id_estructura
        )[0].nombre;
        break;
      case 2:
        this.in_dia.nombre = suplementoModule.all_suplementos.filter(
          (x: FilterDataDto) => x.id_estructura === id_estructura
        )[0].nombre;
        break;
      case 3:
        this.in_dia.nombre = alimentoModule.all_alimentos.filter(
          (x: FilterDataDto) => x.id_estructura === id_estructura
        )[0].nombre;
        break;
      default:
        break;
    }
    dieta_visualizacionModule.dieta_visualizacions[this.in_dia.id_comida - 1][
      this.in_dia.id_dia - 1
    ].push(this.in_dia);
    dieta_visualizacionModule
      .guardarplato_dieta(this.in_dia)
      .then((x: dieta_visualizacion) => {
        let it =
          dieta_visualizacionModule.dieta_visualizacions[x.id_comida - 1][
            x.id_dia - 1
          ];
        let upit = it[it.length - 1];
        let nwit = x;
        upit.id = x.id;
        upit.cantidad = x.cantidad;
        dieta_visualizacionModule.getdieta_estadisticas({
          id_dieta: x.id_dieta,
          id_paciente: 2
        });
        this.shwdialog = false;
      });
  }
}
